import Button from '@/components/button';
import { AUTH_METHOD } from '@/constants/index';
import React, { useEffect, useState } from 'react';
import { ArrowRight, ChevronLeft } from 'react-feather';
import OtpInputting from '../OtpInputting';
// import { loginWithEmailToken, verifyEmailOTP } from 'services/auth';
import IcCheckInbox from '@/public/icons/ic-check-your-inbox.svg';
import Image from 'next/image';
import { ToastError, ToastSuccess } from '@/components/toastify';
import { get } from 'lodash';
import { useRouter } from 'next/router';

const TimeResend = 100;
const { PHONE_NUMBER } = AUTH_METHOD;

function OtpVerifying({ authValues, onBackStep, onNextStep }) {
  const router = useRouter();
  const isLogin = router?.route;
  const overlay = document.getElementById('overlay');
  overlay.style.display = 'none';
  const [timeResend, setTimeResend] = useState(0);
  const [otp, setOtp] = useState('');
  const [isWrongOtp, setWrongOtp] = useState(false);
  const [isWrongOtpThreeTimes, setIsWrongOtpThreeTimes] = useState(false);

  const sendEmailAgain = async (email) => {
    try {
      const check = 'signup';
      if (isLogin == '/login') check = 'login';

      const result = await loginWithEmailToken({
        email: email,
        redirect_url: process.env.NEXT_PUBLIC_LANDING_URL,
        method: check,
      });

      if (get(result, 'data.status') === 1)
        ToastSuccess('Send again successfully.');
      else ToastError('Send again failed.');
    } catch (error) {
      console.log(error);
      ToastError('Send again failed.');
    }
  };

  useEffect(() => {
    setTimeResend(authValues.expireTimeOtp || timeResend);
  }, [authValues]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const timeMinus = timeResend - 1;
      setTimeResend(timeMinus);
    }, 1000);

    if (timeResend === 0) clearTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [timeResend]);

  useEffect(() => {
    if (otp.length === 6) {
      const loginBody = {
        otp: otp,
        endcode_data: authValues.endCodeData,
      };

      onNextStep();
    }
  }, [otp]);

  return (
    <React.Fragment>
      {authValues.method === PHONE_NUMBER || authValues.isSubscribe === true ? (
        <>
          <div className="auth-content__top otp-verifying">
            <Button
              classNames="back-btn has-icon"
              customIcon={<ChevronLeft />}
              title="Back"
              onClick={onBackStep}
            />
          </div>
          <div className="auth-content__middle onboarding-mid">
            <div className="auth-title">
              <div className="title">Enter verify code</div>
              <div className="sub-title">
                Verification code has been sent to{' '}
                {authValues?.method === PHONE_NUMBER ? 'phone number' : 'email'}{' '}
                <span>
                  {authValues?.method === PHONE_NUMBER
                    ? authValues?.areaCode
                    : ''}{' '}
                  {authValues?.value}
                </span>
                . Wrong{' '}
                {authValues?.method === PHONE_NUMBER ? 'number' : 'email'} ?{' '}
                <span className="green-color" onClick={onBackStep}>
                  Edit
                </span>
              </div>
            </div>
            <div className="auth-verify">
              <div className="label">Verification Code</div>
              <div className={`field ${isWrongOtp ? 'invalid' : ''}`}>
                <OtpInputting otp={otp} onSetOtp={setOtp} />
              </div>
              {isWrongOtp && (
                <div className="err-message">
                  The OTP you've entered is incorrect. Please try again.
                </div>
              )}
              {isWrongOtpThreeTimes && (
                <div className="err-message">
                  You've tried 3 times. Sign up again in 1 hour.
                </div>
              )}

              {!isWrongOtpThreeTimes && (
                <div className="resend-otp">
                  {timeResend === 0 ? (
                    <div
                      className="resend-btn"
                      onClick={() => {
                        setTimeResend(TimeResend);
                        setOtp('');
                      }}
                    >
                      <span>Resend code</span>
                      <ArrowRight />
                    </div>
                  ) : (
                    <div className="text">
                      Please wait within <span>{timeResend}</span> seconds to
                      resend
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="auth-check-inbox">
          <div className="auth-check-inbox__top otp-verifying">
            <div className="check-inbox">
              <img src={IcCheckInbox.src} alt="check-ib-icon" />
            </div>
          </div>
          <div className="auth-check-inbox__middle onboarding-mid">
            <div className="auth-title">
              <div className="title">Check your inbox</div>
              <div className="sub-title">
                We've sent you a magic link to{' '}
                {authValues?.method === PHONE_NUMBER
                  ? authValues?.areaCode
                  : ''}{' '}
                {authValues?.value}. Please click the link to confirm your
                address.
              </div>
            </div>
            <div className="divider"></div>
            <div className="auth-verify">
              <div className="label">
                Can't see the email? 
                <span
                  className="green-color"
                  onClick={() => sendEmailAgain(authValues?.value)}
                >
                  Send again
                </span>
              </div>
              <div className="text">
                Wrong email? 
                <span className="green-color" onClick={onBackStep}>
                  Re-enter email
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default OtpVerifying;
