import { getLocaleUrl } from '@/helpers/index';
import ImgTravel from '@/public/images/img-dashboard-signup.png';
import ImgWhiteLogo from '@/public/images/logo-white.svg';
import Image from 'next/image';
import { useRouter } from 'next/router';

function AuthSidebar() {
  const { locale } = useRouter();

  return (
    <div className="auth-page__sidebar">
      <div className="sidebar-logo">
        <a href={getLocaleUrl(locale, '')}>
          <div className="logo">
            <img
              src={ImgWhiteLogo.src}
              layout="fill"
              objectFit="cover"
              alt="hhwt-white-logo"
            />
          </div>
        </a>
      </div>
      <div className="welcome">
        <div className="welcome__img">
          <Image
            src={ImgTravel}
            layout="fill"
            objectFit="contain"
            alt="welcome-image"
          />
        </div>
        <div className="welcome__texts">
          <div className="title">Welcome to HHWT!</div>
          <div className="description">
            Inspire Muslims to travel by simplifying trip planning through
            tailored content.
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthSidebar;
