import { getLocaleUrl } from '@/helpers/index';
import ImgBlackLogo from '@/public/images/img-black-logo.png';
import Image from 'next/image';
import { useRouter } from 'next/router';

function BusinessMobileHeader() {
  const { locale } = useRouter();

  return (
    <div className="auth-page__mobile-header">
      <div className="sidebar-logo logo--black">
        <a href={getLocaleUrl(locale, '')}>
          <div className="logo">
            <Image
              src={ImgBlackLogo}
              layout="fill"
              objectFit="cover"
              alt="hhwt-white-logo"
            />
          </div>
        </a>
      </div>
    </div>
  );
}

export default BusinessMobileHeader;
